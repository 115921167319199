import * as Sentry from "@sentry/react";
import {Client} from '@sentry/core';
import React from "react";
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from "react-router-dom";
import {CURRENT_ENV, ENV, SENTRY_DSN} from "../../../domain/core/constants.ts";

class SentryService {
    private static instance: SentryService;
    private sentryClient: Client|undefined;

    private constructor() {
        if (CURRENT_ENV !== ENV.Dev) {
            this.setClient();
        }
    }

    static getInstance(): SentryService {
        if (!this.instance) {
            this.instance = new SentryService();
        }

        return this.instance;
    }

    private setClient = () => {
        this.sentryClient = Sentry.init({
            dsn: SENTRY_DSN,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation: useLocation,
                    useNavigationType: useNavigationType,
                    createRoutesFromChildren: createRoutesFromChildren,
                    matchRoutes: matchRoutes,
                }),
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration()
            ],
            tracesSampleRate: CURRENT_ENV === ENV.Prod ? 1.0 : 0.1,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            environment: CURRENT_ENV,
        });
    }

    getClient = (): Client|undefined => {
        return this.sentryClient;
    }

    createBrowserRouter = Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

    ErrorBoundary = Sentry.ErrorBoundary;
}

export default SentryService.getInstance();
