import {PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import KeycloakService from "../services/core/KeycloakService.ts";
import {useConfig} from "./ConfigProvider.tsx";
import {useDispatch} from "react-redux";
import {User} from "../../domain/users/models/input/User.ts";
import {useMutationUserAuthSilently, useQueryGetCurrentUser} from "../hooks/api/useUsers.ts";
import {persistor} from "../redux/store.ts";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {CURRENT_ENV, ENV} from "../../domain/core/constants.ts";
import {AuthContext} from "./context/AuthContext.ts";
import {usePostMessage} from "../hooks/core/usePostMessage.tsx";
import {PATH_MAINTENANCE} from "../../ui/routing/constants.ts";
import {clearCurrentUserAction, updateCurrentUserAction} from "../redux/actions/currentUserActions.ts";
import Keycloak from "keycloak-js";
import {updateAppReturnUrlAction} from "../redux/actions/appReturnUrlActions.ts";
import {updateKycbAction} from "../redux/actions/kycbActions.ts";

export const AuthProvider = ({children}: PropsWithChildren) => {
    const config = useConfig();
    const [keycloak, setKeycloak] = useState<Keycloak|undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    const {
        data: rawUserAuth,
        mutateAsync: userAuthSilently
    } = useMutationUserAuthSilently();

    const {
        data: rawUser,
        refetch: refetchRawUser,
        isError: isRawUserError
    } = useQueryGetCurrentUser({
        enabled: false
    });

    const onError = useCallback(() => {
        if (window.location.pathname !== PATH_MAINTENANCE()) {
            window.location.pathname = PATH_MAINTENANCE();
        }
    }, []);

    const initKeycloak = useCallback(() => {
        if (
            !config.specifics?.iam.clientId
            || !config.settings?.authentication.baseUrl
            || !config.settings?.authentication.realm
        ) {
            throw new Error("[Smartpush] Invalid keycloak init.");
        }

        try {
            const kc = KeycloakService.initKeycloak(
                {
                    clientId: config.specifics?.iam.clientId,
                    url: config.settings?.authentication.baseUrl,
                    realm: config.settings?.authentication.realm
                }
            )

            if (rawUserAuth?.access_token && rawUserAuth?.refresh_token) {
                kc.token = rawUserAuth?.access_token;
                kc.refreshToken = rawUserAuth?.refresh_token;
                kc.authenticated = true;
            }

            setKeycloak(kc);
        } catch (e) {
            if (CURRENT_ENV !== ENV.Prod) {
                console.groupCollapsed("[Smartpush] Keycloak error")
                console.log(e);
                console.groupEnd();
            }

            onError();
        }
    }, [config?.settings?.authentication, config?.specifics?.iam, rawUserAuth, onError]);

    /** POST MESSAGE AUTH **/

    const {sendPostMessage} = usePostMessage({handleIncomingMessages: false});

    const onMessage = (message: MessageEvent) => {
        if (message.data && config.specifics?.postMessage?.parseAuthMessage && !rawUserAuth) {
            const data = config?.specifics?.postMessage?.parseAuthMessage(message) as Record<string, string>|undefined;

            if (!data) {
                sendPostMessage({
                    error: "Invalid authentication payload"
                });
                throw new Error("Invalid authentication payload");
            }

            if (data?.returnURL) {
                if (CURRENT_ENV !== ENV.Prod) {
                    console.groupCollapsed("[Smartpush] Configure callback URL");
                    console.log("From:", "postMessage");
                    console.log("Callback URL:", data?.returnURL);
                    console.groupEnd();
                }
                dispatch(updateAppReturnUrlAction(data.returnURL));
            } else if (data?.kycb_user_id) {
                if (CURRENT_ENV !== ENV.Prod) {
                    console.groupCollapsed("[Smartpush] Configure KYCB");
                    console.log("From:", "postMessage");
                    console.log("Data:", data);
                    console.groupEnd();
                }
                dispatch(updateKycbAction({
                    kycbUserId: data.kycb_user_id,
                    insightsDateFilter: data.insights_date_filter
                }));
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const callbackUrl = urlParams.get('callback_url');

                if (callbackUrl) {
                    if (CURRENT_ENV !== ENV.Prod) {
                        console.groupCollapsed("[Smartpush] Configure callback URL");
                        console.log("From:", "queryString");
                        console.log("Callback URL:", callbackUrl);
                        console.groupEnd();
                    }
                    dispatch(updateAppReturnUrlAction(callbackUrl));
                }
            }

            if (data) {
                void userAuthSilently(data);
            }
        }
    }

    usePostMessage({
        handleIncomingMessages: true,
        onMessage: onMessage
    });

    useEffect(() => {
        if (!config?.loading) {
            sendPostMessage({
                ready: true
            });
        }
    }, [config, sendPostMessage]);

    useEffect(() => {
        if (rawUserAuth && !keycloak) {
            initKeycloak();
        }
    }, [initKeycloak, rawUserAuth, keycloak])

    /** END POST MESSAGE AUTH **/

    useEffect(() => {
        if (!config?.specifics?.iam || !config?.settings || config?.specifics?.postMessage?.parseAuthMessage) {
            return;
        }

        initKeycloak();
    }, [config?.specifics, config?.settings, initKeycloak]);

    useEffect(() => {
        if (rawUser) {
            const dispatchCurrentUser = () => {
                dispatch(updateCurrentUserAction(rawUser));
                setLoading(false);
            }
            dispatchCurrentUser();
        } else {
            dispatch(clearCurrentUserAction());
        }
    }, [rawUser, setLoading, dispatch]);

    useEffect(() => {
        if (isRawUserError) {
            onError();
        }
    }, [isRawUserError, onError]);

    const login = useCallback(async () => {
        await keycloak?.login({
            redirectUri: window.location.origin + window.location.pathname
        });
    }, [keycloak])

    const register = useCallback(async (email?: string, urlRedirect?: string) => {
        await keycloak?.register({
            loginHint: email,
            redirectUri: urlRedirect ?? window.location.origin
        });
    }, [keycloak])

    const logout = useCallback(async (redirectUri?: string) => {
        await persistor.purge();
        await keycloak?.logout({
            redirectUri: redirectUri ?? window.location.origin
        });
    }, [keycloak]);

    const updateUser = useCallback((updatedUser: User) => {
        dispatch(updateCurrentUserAction(updatedUser));
    }, [dispatch]);

    const refetchUser = useCallback(async () => {
        if (keycloak?.token) {
            const userData = await refetchRawUser();

            if (userData.isError) {
                onError();
                return;
            }
        }
    }, [refetchRawUser, keycloak, onError]);

    const contextValues = useMemo(() => {
        return ({
            loading,
            userAuth: rawUserAuth,

            updateUser,
            refetchUser,

            login,
            register,
            logout
        });
    }, [loading, updateUser, refetchUser, login, register, logout, rawUserAuth]);

    const onEvent = (eventName: string) => {
        if (eventName === 'onReady' && !keycloak?.token) {
            setLoading(false);
        } else if (eventName === 'onReady' && keycloak?.token) {
            void refetchRawUser();
        }
    }

    const AuthContextRender = () => (
        <AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>
    )

    if (!keycloak) {
        return <AuthContextRender />;
    }

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                pkceMethod: 'S256',
                flow: 'standard',
                timeSkew: 0,
                checkLoginIframe: false,
                enableLogging: CURRENT_ENV !== ENV.Prod,
                token: rawUserAuth?.access_token,
                refreshToken: rawUserAuth?.refresh_token
            }}
            onEvent={onEvent}
        >
            <AuthContextRender />
        </ReactKeycloakProvider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};
